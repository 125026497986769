<template>
  <div class="callback" />
</template>

<script>
import { WxLogin } from '@/api/user'
import { setToken } from '@/utils/auth'
import { ElMessage } from 'element-plus'
export default {
  mounted() {
    console.log('route', this.$route.fullPath)
    const arr = this.$route.fullPath.split('?')
    const queryData = arr[1]
    console.log('queryData', queryData)
    WxLogin(queryData).then(res => {
      if (res.meta.status === 200) {
        this.$store.commit('user/SET_TOKEN', res.data.token)
        setToken(res.data.token)
        // sessionStorage.desktopToken = res.data.token
        this.$router.push('/desktop')
      }
      if (res.meta.status === 10404) {
        localStorage.unionId = res.meta.msg
        this.$router.push('/register')
      }
      if (res.meta.status === 467) {
        ElMessage.error(res.meta.msg)
        this.$router.push('/login')
      }
    }).catch((err) => {
      console.log(err.message)
      localStorage.unionId = err.message
      this.$router.push('/register')
    })
  }
}
</script>

<style>

</style>
